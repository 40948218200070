import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import(/* webpackChunkName: "main" */ "@/views/main-view.vue")
    },
    {
        path: "/url-redirect",
        name: "urlredirect",
        component: () => import(/* webpackChunkName: "urlredirect" */ "../views/url-redirect.vue")
    },
    {
        path: "/europaceRedirect",
        name: "europaceRedirect",
        component: () => import(/* webpackChunkName: "europaceRedirect" */ "../views/europace-redirect-view.vue")
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
 